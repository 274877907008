<template>
  <div>
    <!-- <loading :active.sync="loading"></loading> -->
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn text style="color: #1a347f">
          <a-icon type="left" />

          <span class="fn14-weight7">Parasiticide (4)</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div class="text-center fn24-weight7" style="color: #1a347f">
          Heartgard
        </div>
      </v-col>
      <v-col cols="12">
        <div
          v-for="(name, index) in nameType"
          :key="index"
          style="position: relative"
        >
          <v-row no-gutters align="center">
            <v-col cols="9">
              <div
                class="text-left mb-2 fn22"
                style="color: #4f4f4f; font-weight: 700"
              >
                {{ name }}
              </div>
            </v-col>
          </v-row>
          <v-slide-group v-model="slideGroup" center-active>
            <v-slide-item v-for="(item, index) in items" :key="index">
              <v-card
                class="mb-4 mr-4 rounded-xl"
                max-width="250"
                min-width="250"
                @click="goToELearning(item)"
              >
                <div
                  class="pa-6 mx-auto inline-block"
                  style="background: #bcd1ff; position: relative"
                >
                  <img
                    class="object-cover mx-auto"
                    style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                    "
                    height="120px"
                    width="120px"
                    :src="
                      item.knowledgeImg
                        ? item.knowledgeImg
                        : '@/assets/icon/Laptop.png'
                    "
                  />
                  <img
                    v-if="item.type === 'video'"
                    src="@/assets/icon/Icon_play.png"
                    width="30"
                    style="position: absolute; right: 10px; bottom: 10px"
                  />
                </div>

                <v-card-subtitle
                  class="py-0 text-left"
                  style="color: #6e798c; font-weight: 600; font-size: 11px"
                >
                  Heartgard
                </v-card-subtitle>

                <v-card-text
                  class="text-left py-0 font-weight-bold fn20-weight7"
                  style="color: #081f32"
                >
                  <span>{{ item.knowledge_name | limitHeader }}</span>

                  <div style="font-size: 12px; color: #374a59">
                    {{
                      item.description
                        ? item.description
                        : "Our team was inspired by the seven skills of highly effective programmers created by the TechLead."
                          | limitText
                    }}
                  </div>
                </v-card-text>

                <v-card-actions>
                  <img
                    src="@/assets/BI.png"
                    align="left"
                    justify="left"
                    width="32"
                  />

                  <div
                    class="ml-2 fn11"
                    style="color: #007ae9; font-weight: 600"
                  >
                    Earn {{ item.point }} Points
                  </div>
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <v-row>
            <v-col class="text-right">
              <v-btn text @click="previousSlide" aria-label="Previous slide">
                <a-icon type="left" />
              </v-btn>

              <v-btn text @click="nextSlide(item)" aria-label="Next slide">
                <a-icon type="right" />
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-footer
        width="100%"
        padless
        class="pa-2 ma-0 text-center"
        style="
          background: #f6f6f6;
          color: #1a347f;
          font-weight: 600;
          font-size: 10px;
        "
      >
        <v-col class="text-center pa-0" cols="12">
          <div class="ma-0 text-center">
            © 2010-2021 Boehringer Ingelheim International GmbH. All rights
            reserved.
          </div>
        </v-col>
      </v-footer>
    </v-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
// import footerPage from "@/components/Shared/footer";
export default {
  filters: {
    limitText(val) {
      return val.length > 80 ? val.slice(0, 70) + "..." : val;
    },
    limitHeader(val) {
      return val.length > 28 ? val.slice(0, 28) + "..." : val;
    },
  },
  components: {
    draggable,
    Loading,
    // footerPage,
  },
  data() {
    return {
      slideGroup: 0,
      nameType: ["Videos", "PDF", "Infographic"],
      items: [
        // {
        //   name: "Videos",
        //   objects: [
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //   ],
        // },
        // {
        //   name: "PDF",
        //   objects: [
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //   ],
        // },
        // {
        //   name: "Infographic",
        //   objects: [
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //     {
        //       image: require("@/assets/icon/Laptop.png"),
        //       title: "NexGard Family",
        //       header: "7 Skills of Highly Effective Programmers",
        //       subject:
        //         "Our team was inspired by the seven skills of highly effective programmers created by the TechLead.",
        //     },
        //   ],
        // },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    // this.user = JSON.parse(
    //   Decode.decode(localStorage.getItem("userBiData"))
    // );
    this.getAllHeart();
  },
  methods: {
    async getAllHeart() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledge`
      );
      console.log(
        "knowledgeknowledgeknowledgeknowledgeknowledge",
        response.data
      );
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    nextSlide(item) {
      this.slideGroup++;
      this.slideGroup === item.length ? (this.slideGroup = 0) : "";
    },
    previousSlide() {
      this.slideGroup === 0 ? (this.slideGroup = 0) : this.slideGroup--;
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiData"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    UpdateCate(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("EditCategory");
    },
    ViewCate(val) {
      localStorage.setItem("CategoryData", Encode.encode(val));
      this.$router.push("ViewCategory");
    },
    async DeleteCate(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/categories/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToELearning(item) {
      this.$router.push({
        path: "ELearningHeartgard",
        query: {
          reward: item.id,
        },
      });
    },
  },
};
</script>
